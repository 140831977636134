input[type="text"] {
    width: 100%;
    border: 1px solid #d9d9d9;
    padding: 0.7rem 0.7rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.tag-wrapper{
    opacity: 1;
    cursor: move;
    display: inline-block;
    background: #7064e0;
    border-radius: 4px;
    color: wheat;
    margin: 3px 3px 0;
    padding: 4px 0px 4px 4px;
}

.ReactTags__remove{
    background: red;
    color:white;
    outline: none;
    border: none;
    margin-left: 4px;
    border-radius: 6px;
}
.ReactTable .text-truncate p {
    margin: 14px 0;
}
figure.position-relative img {
    width: 100%;
}
