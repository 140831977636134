div.rt-td { align-self: center; }

.ps__rail-y {
  z-index: 1000;
}

.customLogo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 8px;
}

.customTextNavBar {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 4px;
  padding-left: 4px;
  border-left: 2px solid rgba(199, 199, 199, 0.46);
  font-family: "Times New Roman", serif;
  color: #4a277a;
}

.customLogoText1 {
  font-size: 12px;
  color: #4a277a;
}

.customLogoText2 {
  font-size: 11px;
  color: #5f5372;
}

.verticalLine {
  border-right: 2px solid rgba(199, 199, 199, 0.46);
  padding: 8px;
}

