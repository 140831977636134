// Modals

.modal {
  // Modal Header
  .modal-header {
    background-color: $body-bg;
    border-radius: 0.42rem;
    padding: 0.8rem;
    border-bottom: none;

    // close button
    .close {
      padding: 0.2rem 0.62rem;
      box-shadow: 0 5px 20px 0 rgba($pure-black, 0.1);
      border-radius: 0.357rem;
      background: $white;
      opacity: 1;
      transition: all 0.23s ease 0.1s;
      position: relative;
      transform: translate(-8px, -4px);
      span {
        font-weight: 400;
        font-size: 2rem;
        color: $primary;
      }
      // For hover effect of close btn
      &:hover,
      &:focus,
      &:active {
        opacity: 1;
        outline: none;
        transform: translate(-6px, -2px);
        box-shadow: none;
      }
    }

    &[class*="bg-"] {
      color: $white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .modal-title {
        color: $white;
      }
    }

    // Themed Modals
    &.bg-primary {
      .close {
        span {
          color: $primary;
        }
      }
    }

    &.bg-success {
      .close {
        span {
          color: $success;
        }
      }
    }

    &.bg-info {
      .close {
        span {
          color: $info;
        }
      }
    }

    &.bg-danger {
      .close {
        span {
          color: $danger;
        }
      }
    }

    &.bg-warning {
      .close {
        span {
          color: $warning;
        }
      }
    }

    &.bg-dark {
      .close {
        span {
          color: $dark;
        }
      }
    }
  }

  // Modal Content
  .modal-content {
    border: none;
    // overflow: unset; updated to "visible" as close icon is not visible in IE
    overflow: visible;
    box-shadow: 0 5px 20px 0 rgba($pure-black, 0.1);
  }

  &.modal-centered {
    .modal-dialog {
      display: flex;
      align-items: center;
    }
  }

  // Modal Footer
  .modal-footer {
    border-color: rgba($pure-black, 0.05);
  }
}

// Modal SM
.modal-sm {
  max-width: 400px;
}

// Modal XS
.modal-xs {
  max-width: 300px;
}

// Modal XL
.modal-xl {
  max-width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}

// To remove Max-width of XS Modal in Small Screen

@media (max-width: 576px) {
  .modal {
    padding-right: 1rem;
    padding-left: 1rem;
    .modal-xs,
    .modal-sm {
      max-width: unset;
    }
  }
}

// For Demo Modal Focus After Close

.demo-modal-form {
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba($success, 0.5) !important;
  }
}
